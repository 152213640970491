<template>
  <div class="container-page big">
    <h2 class="title title--h2">
      Карточка соглашения между правительством Российской Федерации и правительствами иностранных государств о поощрении
      и взаимной защите капиталовложений
    </h2>
    <div class="card-investment-agreement">
      <div class="card-investment-agreement__fields">
        <div class="row">
          <div class="cell cell-12 cell-m-12">
            <ComponentInput label="Наименование" mod="rectangular" placeholder="Введите" v-model="card.name" />
          </div>
        </div>
        <div class="row">
          <div class="cell cell-6 cell-m-12">
            <p class="label-field">Страна <span>*</span></p>
            <SelectSingleComponentCheckbox
              v-model="card.countryId"
              placeholder="Выберите"
              :options="getListCountriesAgreementsSelect"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              required
            />
          </div>
          <div class="cell cell-6 cell-m-12">
            <p class="label-field">Группа стран</p>
            <SelectSingleComponentCheckbox
              v-model="card.countryGroupId"
              placeholder="Выберите"
              :options="getListCountriesGroupAgreementsSelect"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
            />
          </div>
        </div>
        <div class="row">
          <div class="cell cell-6 cell-m-12">
            <p class="label-field">Дата подписания <span>*</span></p>
            <DateComponent
              modClass="redesigned"
              v-model="card.signingDate"
              :clearable="false"
              required
              :typeable="false"
            />
          </div>
          <div class="cell cell-6 cell-m-12">
            <p class="label-field">Дата ратификации Российской Стороной</p>
            <DateComponent modClass="redesigned" v-model="card.ratificationDate" :clearable="true" :typeable="false" />
          </div>
        </div>
        <div class="row">
          <div class="cell cell-6 cell-m-12">
            <p class="label-field">Дата вступления в силу</p>
            <DateComponent modClass="redesigned" v-model="card.takeEffectDate" :clearable="true" :typeable="false" />
          </div>
          <div class="cell cell-6 cell-m-12">
            <p class="label-field">От имени правительства <span>*</span></p>
            <SelectSingleComponentCheckbox
              v-model="card.governmentTypeId"
              placeholder="Выберите"
              :options="governmentTypes"
              trackBy="text"
              valueProp="id"
              labelOptions="text"
              required
            />
          </div>
        </div>
        <div class="row">
          <div class="cell cell-12 cell-m-12">
            <h5 class="title title--h5">Источник данных:</h5>
          </div>
          <div class="cell cell-6 cell-m-12">
            <ComponentInput
              label="Название ссылки"
              mod="rectangular"
              placeholder="Введите"
              v-model="card.dataSourceName"
            />
          </div>
          <div class="cell cell-6 cell-m-12">
            <ComponentInput label="Ссылка" mod="rectangular" placeholder="Введите" v-model="card.dataSource" />
          </div>
        </div>
        <div class="row">
          <div class="cell cell-12 cell-m-12">
            <p class="label-field">Документ</p>
            <FileUpload
              width="100%"
              :entity="card"
              @deleteFile="removeFile"
              prop-name="agreementFile"
              @uploaded="fileUploaded"
            />
          </div>
        </div>
        <div class="row">
          <div class="cell cell-12 cell-m-12">
            <ComponentInput
              id="chActive"
              type="checkbox"
              label="Запись опубликована"
              mod="checkbox-green"
              v-model="card.isPublished"
            />
          </div>
        </div>
      </div>
      <div class="card-investment-agreement__button-wrap">
        <ButtonComponent mod="gradient-bg" class="button-redesign--map-apply" @click="save">Сохранить</ButtonComponent>
        <ButtonComponent mod="border" @click="close">Отмена</ButtonComponent>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { useVuelidate } from '@vuelidate/core';
  import { required } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateComponent from '@/common/components/dateComponent';
  import FileUpload from '@/common/components/FileUpload';
  import ButtonComponent from '@/common/components/redesigned/ButtonComponent.vue';
  import SelectSingleComponentCheckbox from '@/common/components/redesigned/SelectRedesigned/SelectSingleComponentCheckbox.vue';
  import Constants from '@/common/constants';

  import {
    INVESTMENT_AGREEMENT_ACTIONS_NAME,
    INVESTMENT_AGREEMENT_GETTERS_NAME,
  } from '../store/investmentAgreement/nameModule';

  export default {
    name: 'InvestmentAgreementCard',
    components: { DateComponent, ButtonComponent, ComponentInput, FileUpload, SelectSingleComponentCheckbox },
    data() {
      return {
        id: this.$route.params.id,
        card: {
          id: 0,
          name: '',
          countryId: '',
          countryGroupId: '',
          signingDate: '',
          ratificationDate: '',
          takeEffectDate: '',
          governmentTypeId: '',
          dataSourceName: '',
          dataSource: '',
          isPublished: false,
          agreementFile: null,
          agreementFileId: null,
        },
        governmentTypes: Constants.governmentTypes,
      };
    },
    created() {
      this.loadDictionaries();
      this.loadCard();
    },
    computed: {
      ...mapGetters({
        getListCountriesAgreementsSelect: INVESTMENT_AGREEMENT_GETTERS_NAME.getListCountriesAgreementsSelect,
        getListCountriesGroupAgreementsSelect: INVESTMENT_AGREEMENT_GETTERS_NAME.getListCountriesGroupAgreementsSelect,
      }),
    },
    methods: {
      ...mapActions({
        getCountriesAgreements: INVESTMENT_AGREEMENT_ACTIONS_NAME.getCountriesAgreements,
        getCountriesGroupAgreements: INVESTMENT_AGREEMENT_ACTIONS_NAME.getCountriesGroupAgreements,
        findCardAgreements: INVESTMENT_AGREEMENT_ACTIONS_NAME.findCardAgreements,
        create: INVESTMENT_AGREEMENT_ACTIONS_NAME.createAgreements,
        update: INVESTMENT_AGREEMENT_ACTIONS_NAME.updateAgreements,
      }),
      async loadCard() {
        if (this.id > 0) {
          const res = await this.findCardAgreements({ id: this.id });
          if (res.success) {
            this.card = res.data;
          } else {
            Constants.alert.fire(Constants.errorTitle, Constants.commonError, 'error');
          }
        }
      },
      loadDictionaries() {
        this.getCountriesAgreements({ countryGroupId: this.card?.countryGroupId || '' });
        this.getCountriesGroupAgreements({ countryId: this.card?.countryId || '' });
      },
      changeDictionaries(type) {
        if (type === 'country') {
          this.getCountriesGroupAgreements({ countryId: this.card?.countryId === null ? '' : this.card.countryId });
        } else if (type === 'countryGroup') {
          this.getCountriesAgreements({
            countryGroupId: this.card?.countryGroupId === null ? '' : this.card.countryGroupId,
          });
        }
      },
      async save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire(Constants.errorTitle, Constants.validationError, 'error');
          return;
        }

        const data = { ...this.card };
        if (data && Object.keys(data).length) {
          for (const key in data) {
            if (key != 'id') !data[key] && delete data[key];
          }
        }

        let res = {};

        if (data.id > 0) {
          res = await this.update(data);
        } else {
          res = await this.create(data);
        }

        if (res.success) {
          Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
          if (data.id > 0) {
            this.$router.push({ name: 'InvestmentAgreementCard', params: { id: this.card.id, action: 'edit' } });
          } else {
            this.$router.push({ name: 'InvestmentAgreementRegistry' });
          }
        } else {
          Constants.alert.fire('Сохранение', Constants.commonError, 'error');
        }
      },
      close() {
        this.$router.push({ name: 'InvestmentAgreementRegistry' });
      },
      fileUploaded(e) {
        this.card.agreementFile = e.data;
        this.card.agreementFileId = e.data ? e.data.id : null;
      },
      removeFile() {
        this.card.agreementFile = null;
        this.card.agreementFileId = null;
      },
    },
    watch: {
      'card.countryId'() {
        this.changeDictionaries('country');
      },
      'card.countryGroupId'() {
        this.changeDictionaries('countryGroup');
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        card: {
          countryId: { required },
          signingDate: { required },
          governmentTypeId: { required },
        },
      };
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/assets/theme/default/title.scss';

  .card-investment-agreement {
    &__button-wrap {
      margin-top: 30px;
    }
  }
  .row {
    padding: 0;
    margin: 0;
    gap: 0 16px;
  }
  .cell {
    padding: 0;
    margin-bottom: 16px;

    &-6 {
      width: calc(50% - 8px);
    }

    &-12 {
      width: 100%;
    }

    @media (max-width: $mobile) {
      &-m {
        &-12 {
          width: 100%;
        }
      }
    }
  }
</style>
